<template>
  <div class="content-copy">
    <div
      v-if="description"
      class="copy sm:text-base mb-8"
      v-html="$md.render(description)"
    ></div>
    <div class="relative w-full aspect-[16/9]">
      <iframe v-not-lazy
        :id="compId"
        class="border-none absolute top-0 left-0 w-full h-full"
        type="text/html"
        :src="videoUrl"
        allow="fullscreen"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import getYouTubeID from 'get-youtube-id'

const uuid = useId()

interface YoutubeVideoProps {
  src: string
  description?: string
}

const props = defineProps<YoutubeVideoProps>()

const videoId = computed(() => {
  return getYouTubeID(props.src)
})

const compId = computed(() => {
  return `ytplayer-${uuid}`
})

const videoUrl = computed(() => {
  const baseUrl = `https://www.youtube.com/embed/`
  if (videoId.value) {
    return baseUrl + videoId.value
  }
  return baseUrl
})
</script>
